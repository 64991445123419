<template>
    <div class="pa-5 confirmModal">
        <v-row>
            <v-col cols="12">
                <div class="d-flex justify-center py-5">
                    <img
                        width="100"
                        height="100"
                        src="@/assets/img/global/warning.svg"
                        alt="warning"
                    />
                </div>
                <v-form ref="form" v-model="valid" >
                    <v-text-field
                        :label="'Email'"
                        v-model="representativeEmail"
                        :rules="emailRules"
                        required
                    ></v-text-field>
                </v-form>
                <!-- <v-card-title class="d-flex justify-center pa-2">Czy chcesz wyenerować nową fakturę</v-card-title> -->
                <v-card-text
                    v-if="isInvoiceAvailable"
                    class="d-flex justify-center pa-2"
                >
                    <span>Wybierz jaki dokument chcesz wysłać?</span>
                </v-card-text>
                <v-card-text
                    v-if="!isInvoiceAvailable"
                    class="d-flex justify-center pa-2"
                >
                    <span>Żadna faktura nie jest aktualnie dostępna</span>
                </v-card-text>
                <v-row> </v-row>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
        },
        isInvoiceAvailable: {
            type: Boolean,
            default: true,
        },
        representativeSelectedEmail: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            confirmType: this.type,
            menu: false,
            text: '',
            date: '',
            representativeEmail: '',
            emailRules: [
                v => !!v || 'E-mail is wymagany.',
                v => /.+@.+\..+/.test(v) || 'Podaj prawidłowy adres e-mail.',
            ],
            valid: false,
        }
    },
    methods: {
        clearConfirmModal() {
            this.text = ''
            this.date = ''
        },
        validate() {
            this.$refs.form.validate()
        },
    },
    computed: {
        getRepresentativeSelectedEmail: {
            get() {
        
                if (this.representativeSelectedEmail) {
         
                    return this.representativeSelectedEmail
                } else {
                    return ''
                }
            },
            set(newVal) {
                this.representativeSelectedEmail = newVal
            },
        },
    },
    mounted() {
    
        this.representativeEmail = this.getRepresentativeSelectedEmail
        this.$root.$on('clearModal', () => {
            this.clearConfirmModal()
        })
    },
}
</script>
