<template>
    <div class="page">
        <div v-if="this.data && this.getDevices" class="subpage">
            <div class="header">
                <div class="logo">
                    <img
                        v-if="getSettingsDetails.logo"
                        :src="`${path}/client_logo.png`"
                        alt="entelli logo"
                    />
                </div>
                <div class="header__invoice-wrapper">
                    <div>
                        <span>Data wystawienia:</span>
                    </div>
                    <div>
                        <span>{{ this.invoiceCreatedAt }}</span>
                    </div>
                </div>
            </div>
            <div class="title">
                <div class="title__block-1"></div>
                <div class="title__block-2"><span>RAPORT</span></div>
                <div class="title__block-3"></div>
            </div>
            <div class="clientDescription">
                <div class="column-left">
                    <div class="row-1">
                        <div>
                            <span>Klient:</span>
                        </div>
                        <div class="column-left__item-wrapper">
                            <span>{{
                                $get(client.client, 'annex.contractor.name', '')
                            }}</span>
                            <span
                                >{{ `${$t('service:str')} `
                                }}{{
                                    $get(
                                        client.client,
                                        'annex.contractor.street',
                                        ''
                                    )
                                }}
                                {{
                                    $get(
                                        client.client,
                                        'annex.contractor.houseNumber',
                                        ''
                                    )
                                }},
                                {{
                                    $get(
                                        client.client,
                                        'annex.contractor.postCode',
                                        ''
                                    )
                                }}
                                {{
                                    $get(
                                        client.client,
                                        'annex.contractor.city',
                                        ''
                                    )
                                }}
                            </span>
                            <span
                                >NIP:
                                {{
                                    $get(
                                        client.client,
                                        'annex.contractor.NIP',
                                        ''
                                    )
                                }}</span
                            >

                            <span>
                                {{
                                    $get(
                                        client.client,
                                        'annex.contractor.email',
                                        ''
                                    )
                                }}</span
                            >
                        </div>
                    </div>
                </div>

                <div class="column-right">
                    <div class="row-1">
                        <div class="column-right__item-wrapper">
                            <div>
                                <span>Do faktury numer:</span>
                            </div>
                            <div>
                                <span
                                    >FA/{{
                                        getNewInvoiceNumber(
                                            this.data.settlementTableData.index
                                        )
                                    }}/{{ new Date().getFullYear() }}/P5</span
                                >
                            </div>

                            <div>
                                <span>Numer umowy</span>
                            </div>
                            <div>
                                <span>{{
                                    $get(client.client, 'contractNo', false) ||
                                    $get(
                                        client.client,
                                        'externalContractNo',
                                        ''
                                    )
                                }}</span>
                            </div>

                            <div>
                                <span>ID rozliczenia</span>
                            </div>
                            <div>
                                <span>{{
                                    this.data.settlementTableData.content.index
                                }}</span>
                            </div>
                            <div>
                                <span>Data rozliczenia</span>
                            </div>
                            <div>
                                <span>{{ this.calculatedDate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="central-table-wrapper">
                <table>
                    <tr>
                        <th class="pl-2">Lp.</th>
                        <th class="pl-3">
                            <div class="row-items">
                                <span>Model urządzenia</span>
                                <span>Numer seryjny</span>
                            </div>
                        </th>

                        <th class="pl-2">
                            <div class="row-items">
                                <span style="font-weight: bold;"
                                    >Abonament:
                                </span>
                                <span>Cena</span>
                                <span>Pakiet wydruków mono</span>
                                <span>Pakiet wydruków kolor</span>
                                <span>Pakiet skanów</span>
                                <span>Monitoring</span>
                            </div>
                        </th>
                        <th class="pl-2">
                            <div class="row-items">
                                <span style="font-weight: bold;"
                                    >Mono ponad abonament:</span
                                >
                                <span>Koszt 1 wydruku</span>
                                <span>Nakład</span>
                                <span>Koszt nakładu</span>
                            </div>
                        </th>
                        <th class="pl-2">
                            <div class="row-items">
                                <span style="font-weight: bold;"
                                    >Kolor ponad abonament:</span
                                >
                                <span>Koszt 1 wydruku</span>
                                <span>Nakład</span>
                                <span>Koszt nakładu</span>
                            </div>
                        </th>
                        <th class="pl-2">
                            <div class="row-items">
                                <span style="font-weight: bold;">Skany:</span>
                                <span>Koszt 1 skanu</span>
                                <span>Ilość</span>
                            </div>
                        </th>
                        <th class="pl-2 pr-1">Koszt miesięczny</th>
                    </tr>
                    <tr v-for="(item, index) in getDevices()" :key="index">
                        <td>{{ item.postionNumber }}</td>
                        <td>
                            <div class="row-items">
                                <span
                                    >{{
                                        $get(
                                            item,
                                            'rentDevices.device.model.manufacturer',
                                            ''
                                        )
                                    }}
                                    {{
                                        $get(
                                            item,
                                            'rentDevices.device.model.model',
                                            ''
                                        )
                                    }}</span
                                >

                                <span>
                                    {{
                                        $get(item, 'rentDevices.device.sn', '')
                                    }}
                                </span>
                            </div>
                        </td>
                        <td>
                            <div class="row-items">
                                <span>
                                    {{
                                        (
                                            $get(item, 'rentDevices.rent', 0) +
                                            $get(
                                                item,
                                                'rentDevices.monoBundleCost',
                                                0
                                            ) +
                                            $get(
                                                item,
                                                'rentDevices.colorBundleCost',
                                                0
                                            )
                                        ).toFixed(2)
                                    }}
                                    PLN
                                </span>
                                <span
                                    >{{
                                        item.rentDevices.monoBundle
                                            ? $get(
                                                  item,
                                                  'rentDevices.monoBundle',
                                                  0.0
                                              )
                                            : '0'
                                    }}
                                </span>

                                <span
                                    >{{
                                        item.rentDevices.colorBundle
                                            ? $get(
                                                  item,
                                                  'rentDevices.colorBundle',
                                                  0.0
                                              )
                                            : '0'
                                    }}
                                </span>
                                <span>Nieograniczony</span>
                                <span
                                    >{{
                                        $get(
                                            item,
                                            'rentDevices.monitoringCost',
                                            0.0
                                        ).toFixed(2)
                                    }}
                                    PLN
                                </span>
                            </div>
                        </td>
                        <td>
                            <div class="row-items">
                                <span>
                                    {{
                                        $get(
                                            item,
                                            'rentDevices.monoPrice',
                                            0.0
                                        ).toFixed(3)
                                    }}
                                    PLN</span
                                >
                                <span>
                                    {{
                                        bundleOverLimit(
                                            item.rentDevices.monoBundle,
                                            item.monthlyMono
                                        )
                                    }}
                                </span>
                                <span
                                    >{{
                                        bundleOverLimitCost(
                                            bundleOverLimit(
                                                item.rentDevices.monoBundle,
                                                item.monthlyMono
                                            ),
                                            item.rentDevices.monoPrice
                                        ).toFixed(2)
                                    }}
                                    PLN</span
                                >
                            </div>
                        </td>
                        <td>
                            <div class="row-items">
                                {{
                                    $get(
                                        item,
                                        'rentDevices.colorPrice',
                                        0.0
                                    ).toFixed(3)
                                }}
                                PLN
                                <br />
                                {{
                                    bundleOverLimit(
                                        item.rentDevices.colorBundle,
                                        item.monthlyColor
                                    )
                                }}
                                <br />
                                {{
                                    bundleOverLimitCost(
                                        bundleOverLimit(
                                            item.rentDevices.colorBundle,
                                            item.monthlyColor
                                        ),
                                        item.rentDevices.colorPrice
                                    ).toFixed(2)
                                }}
                                PLN
                            </div>
                        </td>
                        <td>
                            <div class="row-items">
                                <span
                                    >{{
                                        $get(
                                            item,
                                            'rentDevices.scanPrice',
                                            0.0
                                        ).toFixed(2)
                                    }}
                                    PLN</span
                                >

                                <span>{{
                                    $get(item, 'monthlyScan', 0.0).toFixed(2)
                                }}</span>
                            </div>
                        </td>
                        <td>
                            <div class="row-items">
                                {{
                                    totalMonthlyCost(
                                        item.rentDevices.rent,
                                        item.rentDevices.monoBundleCost,
                                        item.rentDevices.colorBundleCost,
                                        bundleOverLimitCost(
                                            bundleOverLimit(
                                                item.rentDevices.monoBundle,
                                                item.monthlyMono
                                            ),
                                            item.rentDevices.monoPrice
                                        ),
                                        bundleOverLimitCost(
                                            bundleOverLimit(
                                                item.rentDevices.colorBundle,
                                                item.monthlyColor
                                            ),
                                            item.rentDevices.colorPrice
                                        ),
                                        bundleOverLimitCost(
                                            bundleOverLimit(
                                                item.rentDevices.scanBundle,
                                                item.monthlyScan
                                            ),
                                            item.rentDevices.scanPrice
                                        ),
                                        $get(
                                            item,
                                            'rentDevices.monitoringCost',
                                            0.0
                                        )
                                    ).toFixed(2)
                                }}
                                PLN
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="before-summary-wrap">
                <div class="thanks-text">
                    <span>Dziękujemy za korzystanie z naszych usług.</span>
                </div>
                <div v-if="checkIsContentLastRaport" class="total-netto">
                    <span
                        ><span>Razem netto:</span>
                        <span>{{ summaryNettoTable().toFixed(2) }}</span>
                        PLN</span
                    >
                </div>
            </div>
            <div v-if="checkIsContentLastRaport" class="summary-wrapper">
                <div class="col-left"></div>
                <div class="col-right">
                    <div class="total-brutto">
                        <div class="col-left">
                            <span>Razem brutto <br />do zapłaty:</span>
                        </div>
                        <div class="col-right">
                            <span
                                >{{ summaryBruttoTable().toFixed(2) }} PLN</span
                            >
                        </div>
                    </div>
                    <div class="numtoword" v-if="priceFormater">
                        Słownie:
                        {{
                            priceFormater.convert(
                                parseFloat(this.summaryBruttoTable().toFixed(2))
                            )
                        }}
                    </div>
                </div>
            </div>

            <div class="footer">
                <span>{{ getSettingsDetails.website }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import store from '../../../../store'
import jspdf from 'jspdf'
import domtoimage from 'dom-to-image'
import moment from 'moment'

import PriceFormater from 'price-to-words-pl'
import { getNewInvoiceNumber } from './utils/invoiceNumberModifier'
export default {
    props: {
        type: {
            type: String,
        },
        data: {
            type: Object,
        },
        client: {
            type: Object,
        },
        devices: {
            type: Object,
        },
        allDevices: {
            type: Object,
        },
    },
    data() {
        return {
            path: `${process.env.VUE_APP_SERVER}/secured/logo/`,
            forTechnic: true,
            vat: 23,
            partsBrutto: 0,
            isPrice: false,
            x: '',
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: 2,
                masked: false,
            },
            emailMenu: false,
            representativeMail: '',
            emailSend: false,
            priceFormater: '',
            devicesArray: [],
            currency: ' PLN',
            getNewInvoiceNumber: getNewInvoiceNumber,
        }
    },

    computed: {
        ...mapGetters([
            'getServiceDetails',
            'getSettingsDetails',
            'getPrevIntervention',
            'getEstimates',
            'getProfileDetails',
        ]),
        checkIsContentLastRaport() {
            const listDevices = this.getDevices()
            for (let device of listDevices) {
                if (device?.onLastRaport) {
                    return true
                }
            }
            return false
        },
        invoiceCreatedAt() {
            return (
                moment(this.data.settlementCreatedAt).format(`DD-MM-YYYY`) || ''
            )
        },
        calculatedDate() {
            return (
                moment(this.client.client.lastSettlement?.createdAt).format(
                    `DD-MM-YYYY`
                ) || ''
            )
        },
    },

    components: {},

    methods: {
        invoiceRentDevices(rentDevices) {
            let sum = 0.0
            sum = rentDevices.reduce(function (acc, val) {
                return acc + val.rent
            }, 0)

            return sum.toFixed(2)
        },
        summaryMono(items) {
            let sum = 0

            items.rentSettlements.forEach(element => {
                const monthlyMono = element.monthlyMono

                const devices = items.rentAnnex.rentDevices.filter(
                    e => e._id === element.rentDevices
                )
                const monoPrice = devices[0].monoPrice
                sum += monoPrice * monthlyMono
            })
            sum = sum.toFixed(2)
            return { sum }
        },
        summaryColor(items) {
            let sum = 0

            items.rentSettlements.forEach(element => {
                const monthlyColor = element.monthlyColor

                const devices = items.rentAnnex.rentDevices.filter(
                    e => e._id === element.rentDevices
                )
                const monoPrice = devices[0].colorPrice
                sum += monoPrice * monthlyColor
            })
            sum = sum.toFixed(2)
            return { sum }
        },
        summaryScan(items) {
            let sum = 0

            items.rentSettlements.forEach(element => {
                const monthlyScan = element.monthlyScan

                const devices = items.rentAnnex.rentDevices.filter(
                    e => e._id === element.rentDevices
                )
                const scanPrice = devices[0].scanPrice
                sum += scanPrice * monthlyScan
            })
            sum = sum.toFixed(2)
            return { sum }
        },
        totalPriceBrutto() {
            const total =
                this.data.settlementTableData.content.monthlyCosts * 0.23 +
                this.data.settlementTableData.content.monthlyCosts
            return total.toFixed(2)
        },
        getDevices() {
            return this.devices.devices
        },
        summaryRow(mono, color, scan) {
            return (mono + color + scan).toFixed(2)
        },
        summaryNettoTable() {
            let total = 0.0

            // TOTALNA ZMIANA NA ZLICZANIE ZE WSZYSTKICH URZADZEN

            const devices = this.allDevices.devices.map(element => {
                return element
            })

            devices.forEach(element => {
                total +=
                    element.rentDevices?.rent +
                    element.rentDevices.monoBundleCost +
                    element.rentDevices.colorBundleCost +
                    element.rentDevices.monoPrice *
                        this.bundleOverLimit(
                            element.rentDevices.monoBundle,
                            element.monthlyMono
                        ) +
                    element.rentDevices.colorPrice *
                        this.bundleOverLimit(
                            element.rentDevices.colorBundle,
                            element.monthlyColor
                        ) +
                    element.rentDevices.monitoringCost
            })

            return total
        },
        summaryBruttoTable() {
            const netto = this.summaryNettoTable()
            return netto * 0.23 + netto
        },
        bundleOverLimit(limit, monthly) {
            if (limit <= monthly) {
                return monthly - limit
            } else {
                return 0
            }
        },
        bundleOverLimitCost(bundleOverLimit, cost) {
            if (bundleOverLimit > 0) {
                return bundleOverLimit * cost
            } else {
                return 0
            }
        },
        totalMonthlyCost(
            rent,
            monoBundleCost,
            colorBundleCost,
            bundleOverLimitMonoCost,
            bundleOverLimitColorCost,
            bundleOverLimitScanCost,
            monitoringCost
        ) {
            return (
                rent +
                monoBundleCost +
                colorBundleCost +
                bundleOverLimitMonoCost +
                bundleOverLimitColorCost +
                bundleOverLimitScanCost +
                monitoringCost
            )
        },
    },

    mounted() {
        this.priceFormater = new PriceFormater()
        this.devicesArray = this.getDevices()
    },

    beforeMount() {
        store.dispatch('fetchSettings', {
            type: 'add',
        })
    },
}
</script>

<style lang="scss" scoped>
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    color: #4a4a4a;
}
* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.page {
    width: 210mm;
    height: 297mm;
    margin: 10mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    position: relative;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.subpage {
    height: 100%;
    .header {
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 30px;
        display: grid;
        grid-template-columns: 2fr 1fr;
        .logo {
            padding-left: 10px;
            img {
                max-width: 203px;
                max-height: 45px;
                display: block;
            }
        }

        &__invoice-wrapper {
            display: grid;
            grid-template-columns: 2fr 1fr;
            font-size: 0.625em;
            text-align: center;
            justify-content: center;
            align-items: center;
        }
    }

    .title {
        margin-top: 30px;
        display: flex;
        font-size: 1.75em;
        font-weight: 400;

        &__block-1 {
            width: 500px;
            height: 50px;
            background-color: #4c517d;
            display: block;
        }

        &__block-2 {
            display: flex;
            height: 50px;
            padding-left: 40px;
            padding-right: 40px;
            line-height: 50px;
        }

        &__block-3 {
            height: 50px;
            float: left;
            flex-grow: 1;
            background-color: #4c517d;
            display: block;
        }
    }

    .clientDescription {
        margin-top: 50px;
        display: grid;
        grid-template-columns: 500px 1fr;

        .column-left {
            display: grid;
            padding-left: 50px;

            &__item-wrapper {
                padding: 0px;
                margin: 0px;
                display: grid;
                grid-template: column;
                font-size: 0.6875em;
            }
        }
        .column-right {
            display: grid;

            &__item-wrapper {
                display: grid;
                margin: 0px;
                grid-template-columns: 1fr 1fr;
                font-size: 0.6875em;
            }

            .row-2 {
                margin-top: 30px;
            }
        }
    }
    .middle-text {
        padding-left: 50px;
        margin-top: 10px;
        font-size: 0.7rem;
    }
    .central-table-wrapper {
        margin-left: 50px;
        margin-top: 50px;

        table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 95%;
            border: 1px solid rgb(128, 128, 128);
            font-size: 0.625em;
        }

        th {
            background-color: #4c517d;
            color: #f5f5f5;
            font-weight: 300;
            text-align: left;
        }

        th,
        td {
            word-wrap: break-word;
            max-width: 150px;

            padding-top: 10px;
            padding-bottom: 10px;

            .row-items {
                display: grid;
                grid-template-columns: 1fr;
            }
        }
        td {
            text-align: left;
            padding: 10px 5px 10px 10px;
        }

        tr:nth-child(2n) {
            background-color: #f5f5f5;
        }
    }
    .thanks-text {
        padding-left: 0px;
        margin-top: 5px;
        font-size: 0.75em;
    }
    .before-summary-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-left: 50px;
        padding-right: 38px;
        margin-top: 10px;
        .thanks-text {
            font-size: 0.75em;
        }

        .total-netto {
            font-weight: 700;
            line-height: 14px;
            font-size: 0.75em;
            color: #4a4a4a;
            justify-self: end;
        }
        .total-netto > span > span:nth-child(2n) {
            margin-left: 20px;
        }
    }

    .summary-wrapper {
        margin-top: 60px;
        padding-top: 10px;
        padding-left: 50px;
        padding-right: 38px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .col-left {
            font-size: 0.75em;
            .payment-condition {
                margin-top: 20px;
                display: flex;
                flex-direction: column;

                &__inner-wrapper {
                    margin-top: 2px;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }
            }
        }

        .col-right {
            display: grid;
            text-align: right;

            .total-brutto {
                margin-top: 5px;
                width: 80%;
                height: 52px;
                justify-self: end;
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
                padding: 5px 15px 5px 15px;
                background-color: #4c517d;
                text-align: start;
                color: white;
            }
            .numtoword {
                margin-top: 5px;
                font-size: 0.5em;
                text-align: left;
                justify-self: end;
            }

            .mini-summary {
                text-align: right;
                margin-top: 15px;

                table {
                    width: 65%;
                    float: right;
                }

                th,
                td {
                    font-size: 0.5em;
                    padding: 0px 0px 0px 0px;
                }
            }
        }
    }
    .significant {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-top: 30px;
        padding-left: 50px;
        margin-bottom: 10px;
        font-size: 0.9rem;
        .col-left {
            display: flex;
            flex-direction: column;

            align-items: flex-start;
        }
        .col-right {
            display: flex;
            flex-direction: column;
            padding-top: 20px;
            padding-left: 30px;
            align-items: flex-start;

            hr.line {
                border: 1px solid rgb(1, 1, 1, 0.5);
                margin-bottom: 10px;
                width: 70%;
            }
        }
    }
    .footer {
        margin-left: -1px;
        width: 210mm;
        height: 25px;
        margin-bottom: 20px;
        color: white;
        text-align: center;
        position: absolute;
        bottom: 0;
        background-color: #4c517d;
    }
}

@page {
    size: A4;
    margin: 0;
}
@media print {
    html,
    body {
        width: 210mm;
        height: 297mm;
        padding: 0px !important;
    }

    .page {
        margin: 2px;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
        -webkit-print-color-adjust: exact;
    }

    .subpage {
        height: 256mm;
    }
}
</style>
