export const getNewInvoiceNumber = number => {
    number = number.toString()
    let numberArr = Array.from(String(number))
    const howManyZerosToAdd = 6 - number.length
    if (howManyZerosToAdd > 0) {
        for (let i = 0; i < howManyZerosToAdd; i++) {
            numberArr.unshift('0')
        }
    }

    return numberArr.join('')
}
