<template>
    <v-container>
        <DocumentGenerator></DocumentGenerator>
    </v-container>
</template>
<script>
import DocumentGenerator from '../../../components/Registries/Rent-contracts/DocumentGenerator/DocumentGenerator.vue'
import store from './../../../store/index'
export default {
    data() {
        return {}
    },
    method: {},
    components: {
        DocumentGenerator,
    },
    beforeRouteEnter(to, from, next) {
        // store.dispatch('fetchService', { id: to.query.id, next })
        next()
    },
}
</script>
