<template>
    <v-app>
        <div ref="content" class="content">
            <div class="sendButtons">
                <v-btn
                    @click="openSelectEmailMessageModal"
                    dark
                    class="ml-1 buttons--add"
                    icon
                >
                    <v-icon small>mdi-email-fast</v-icon>
                </v-btn>
            </div>
            <PDFInvoice
                class="invoice"
                ref="invoiceRef"
                v-if="!selectBundleInvoice()"
                :data="data"
                :client="client"
            />
            <PDFInvoiceBundle
                class="invoice"
                ref="invoiceRef"
                v-if="selectBundleInvoice()"
                :data="data"
                :client="client"
            />

            <div v-if="!selectBundleInvoice()">
                <div
                    v-for="(list, index) in this.splitedDevices.devices"
                    :key="'A' + index"
                >
                    <PDFRaportOne
                        :allDevices="devices"
                        ref="raportRef"
                        :devices="list"
                        :client="client"
                        :data="data"
                    />
                </div>
            </div>
            <div v-if="selectBundleInvoice()">
                <div
                    ref="raportRef"
                    v-for="(list, index) in this.splitedDevices.devices"
                    :key="'B' + index"
                >
                    <PDFRaportBundle
                        :allDevices="devices"
                        :devices="list"
                        :client="client"
                        :data="data"
                    />
                </div>
            </div>
        </div>

        <ConfirmModal
            :openConfirm="openEmailMessageContentConfirm"
            @close-confirm="closeEmailMessageContentConfirm"
        >
            <div slot="main">
                <EmailConfirmContent
                    ref="emailConfirmContent"
                    :representativeSelectedEmail="
                        getRepresentativeSelectedEmail()
                    "
                />
            </div>
            <div slot="buttons">
                <div>
                    <div class="d-flex justify-center">
                        <v-checkbox
                            v-model="checkBoxInvoice"
                            :label="'Faktura'"
                        ></v-checkbox>
                        <v-checkbox
                            class="pl-5"
                            v-model="checkBoxRaport"
                            :label="'Raport'"
                        ></v-checkbox>
                    </div>
                </div>
                <div>
                    <v-progress-linear
                        v-if="emailLoading"
                        color="#19aa8d"
                        indeterminate
                        rounded
                        height="6"
                    >
                    </v-progress-linear>
                </div>
                <div class="response-message text-center">
                    <p
                        class="response-message-success"
                        v-if="emailSendSuccess === 'OK'"
                    >
                        Wiadomość wysyłana pomyślnie
                    </p>
                    <p
                        class="response-message-error"
                        v-if="emailSendSuccess === 'ERROR'"
                    >
                        Wystąpił błąd w wysyłaniu wiadomości
                    </p>
                </div>
                <div class="d-flex justify-center pt-4">
                    <v-btn
                        width="210"
                        height="35"
                        color="#445d75"
                        class="white--text mx-2"
                        @click="emailSendHandler"
                    >
                        Potwierdź wybór</v-btn
                    >
                </div>
            </div>
        </ConfirmModal>
    </v-app>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import PDFInvoice from './PDFInvoice.vue'
import PDFInvoiceBundle from './PDFInvoiceBundle.vue'
import PDFRaportOne from './PDFRaportOne.vue'
import PDFRaportBundle from './PDFRaportBundle.vue'
import EmailConfirmContent from '../Tabs/Rent-settlements/Tabs/Table/EmailMessageChooseConfirm.vue'
import jsPDF from 'jspdf'

import * as html2canvas from 'html2canvas'

export default {
    data: function () {
        return {
            data: {},
            client: {},
            devices: {},
            splitedDevices: {
                devices: [],
            },
            sendEmail: false,
            sendRaport: false,
            userEmail: '',
            dataSent: false,
            openEmailMessageContentConfirm: false,
            checkBoxInvoice: true,
            checkBoxRaport: false,
            subject: '',
            message: '',
            emailLoading: false,
            emailSendSuccess: '',
        }
    },
    computed: {
        ...mapGetters([
            'getSelectedSettlementToPdf',
            'getServiceDetails',
            'getSettingsDetails',
        ]),
    },
    components: {
        PDFInvoice,
        PDFInvoiceBundle,
        PDFRaportOne,
        PDFRaportBundle,
        EmailConfirmContent,
    },
    methods: {
        ...mapActions(['fetchSettings']),
        genCode(len) {
            const chars =
                '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
            let code = ''
            for (let i = 0; i < len; i++) {
                let index = Math.floor(Math.random() * chars.length)
                code += chars[index]
            }
            return code
        },
        getRepresentativeSelectedEmail() {
            return this.client.client.annex.representativeSelectedEmail || ''
        },
        closeEmailMessageContentConfirm() {
            this.openEmailMessageContentConfirm = false
        },
        openSelectEmailMessageModal() {
            this.emailSendSuccess = ''
            this.openEmailMessageContentConfirm = true
        },
        selectBundleInvoice() {
            const rentDevices = this.data.settlementTableData.content
                .rentDevices
            let selectedBundleInvoice = false
            rentDevices.forEach(device => {
                if (device.monoBundleCost > 0 || device.colorBundleCost > 0) {
                    selectedBundleInvoice = true
                }
            })
            return selectedBundleInvoice
        },

        async createSinglePage(doc, pageInfo) {
            try {
                const canvas = await html2canvas(pageInfo, { scale: 3 })

                // JPEG HERE MOST IMPORTANT
                var img = canvas.toDataURL('image/jpeg')
                const imgProps = doc.getImageProperties(img)

                const pdfWidth = doc.internal.pageSize.getWidth()

                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width

                doc.addImage(img, 'JPEG', 0, 0, pdfWidth, pdfHeight)

                return doc
            } catch (err) {
                console.log(err)
            }
        },

        async emailSendHandler() {
            this.emailSendSuccess = ''

            const emailToSend = this.$refs.emailConfirmContent
                .representativeEmail
            const validationResult = this.$refs.emailConfirmContent.valid

            if (!validationResult) {
                return false
            }
            // CONTINUE SENDING
            this.emailLoading = true
            await this.fetchSettings({ type: 'add' })

            const companyPhone = this.getSettingsDetails.companyPhone

            this.subject = 'Rozliczenie umowy'
            this.message = {}

            const outsideCompanyEmailData = {
                alertSource: { type: 'INVOICE_AND_RAPORT_SEND_TO_CLIENT' },
                mail: emailToSend,
                invoiceBoolean: this.checkBoxInvoice,
                raportBoolean: this.checkBoxRaport,
                message: this.message,
                subject: this.subject,
                companyPhone,
            }
            let doc = new jsPDF('p', 'pt', 'A4')

            if (this.checkBoxInvoice && !this.checkBoxRaport) {
                doc = await this.createSinglePage(
                    doc,
                    this.$refs.invoiceRef.$el.firstChild
                )
            }
            if (!this.checkBoxInvoice && this.checkBoxRaport) {
                // MORE THAN ONE RAPORT CODE
                if (this.$refs.raportRef.length > 1) {
                    for (let i = 0; i < this.$refs.raportRef.length; i++) {
                        try {
                            await this.createSinglePage(
                                doc,
                                this.$refs.raportRef[i].querySelector(
                                    '.subpage'
                                )
                            )

                            if (i !== this.$refs.raportRef.length - 1) {
                                doc.addPage()
                                doc.setPage(doc.internal.getNumberOfPages())
                            }
                        } catch (err) {
                            console.log(err)
                        }
                    }
                } else {
                    await this.createSinglePage(
                        doc,
                        this.$refs.raportRef[0].querySelector('.subpage')
                    )
                }
            }

            if (this.checkBoxRaport && this.checkBoxInvoice) {
                doc = await this.createSinglePage(
                    doc,
                    this.$refs.invoiceRef.$el.firstChild
                )
                doc.addPage()
                doc.setPage(2)

                if (this.$refs.raportRef.length > 1) {
                    // MORE THAN ONE RAPORT CODE
                    for (let i = 0; i < this.$refs.raportRef.length; i++) {
                        try {
                            await this.createSinglePage(
                                doc,
                                this.$refs.raportRef[i].querySelector(
                                    '.subpage'
                                )
                            )

                            if (i !== this.$refs.raportRef.length - 1) {
                                doc.addPage()
                                doc.setPage(doc.internal.getNumberOfPages())
                            }
                        } catch (err) {
                            console.log(err)
                        }
                    }
                } else {
                    await this.createSinglePage(
                        doc,
                        this.$refs.raportRef[0].querySelector('.subpage')
                    )
                }
            }

            try {
                let form = new FormData()
                form.append('case', 'invoicesAndRaports')
                form.append('data', JSON.stringify(outsideCompanyEmailData))
                form.append('code', `${this.genCode(8)}`)

                form.append('doc', doc.output('blob'))
                let res = await new this.Rest('POST', '/service/sendEstimate')
                    .setBody(form)
                    .send()

                if (res.status === 200) {
                    this.emailLoading = false
                    this.emailSendSuccess = 'OK'
                    const sleep = m => new Promise(r => setTimeout(r, m))
                    await sleep(1000)
                    this.closeEmailMessageContentConfirm()
                }
                return true
            } catch (error) {
                this.emailLoading = false
                this.emailSendSuccess = 'ERROR'
                console.error(error)
            }
        },
    },

    created() {
        this.data = JSON.parse(
            sessionStorage.getItem('selectedSettlementToPdf')
        )
        this.client = JSON.parse(
            sessionStorage.getItem('clientSettlementToPdf')
        )

        this.devices = JSON.parse(
            sessionStorage.getItem('devicesSettlementToPdf')
        )

        const chunkSize = 5

        let tempDevice = this.devices.devices

        for (let j = 0; j < tempDevice.length; j++) {
            tempDevice[j] = {
                ...tempDevice[j],
                postionNumber: j + 1,
            }
            if (j === tempDevice.length - 1) {
                tempDevice[j] = {
                    ...tempDevice[j],
                    onLastRaport: true,
                }
            }
        }

        for (let i = 0; i < this.devices.devices.length; i += chunkSize) {
            let chunk = this.devices.devices.slice(i, i + chunkSize)
            this.splitedDevices.devices.push({ devices: chunk })
        }
    },

    updated: function () {
        this.$nextTick(function () {
            if (this.$refs.content && !this.dataSent) {
                // emailSendHandler()
            }
        })
    },

    mounted() {
        this.sendEmail = this.$route.query.sendInvoice === 'true'
        this.sendRaport = this.$route.query.sendRaport === 'true'
        this.userEmail = this.$route.query.userEmail
    },

    beforeMount() {},
}
</script>

<style lang="scss">
.response-message {
    &-success {
        color: green;
    }

    &-error {
        color: red;
    }
}
.content {
    width: 1900px;
    height: 100%;
    position: relative;
}
.sendButtons {
    position: absolute;
    top: 20px;
    right: 450px;

    & .buttons--add {
        margin-top: 20px;
        margin-right: 50px;
    }
}

@media print {
    .v-application--wrap {
        position: absolute;
        max-height: 100%;
    }
    .content {
        width: 100%;
    }

    .sendButtons {
        display: none;
    }
}
</style>
