<template>
    <div class="page">
        <div v-if="this.data" class="subpage">
            <div class="header">
                <div class="logo">
                    <img
                        v-if="getSettingsDetails.logo"
                        :src="`${path}/client_logo.png`"
                        alt="entelli logo"
                    />
                </div>
                <div class="header__invoice-wrapper">
                    <div class="header__invoice-wrapper-info">
                        <span>Numer faktury:</span>
                        <span
                            >FA/{{
                                getNewInvoiceNumber(
                                    this.data.settlementTableData.index
                                )
                            }}/{{ new Date().getFullYear() }}/P5</span
                        >
                    </div>
                    <div class="header__invoice-wrapper-info">
                        <span>Data wystawienia:</span
                        ><span>{{ this.invoiceCreatedAt }}</span>
                    </div>
                    <div class="header__invoice-wrapper-info">
                        <span>Data dostawy / wykonania usługi:</span
                        ><span>{{ this.invoiceCreatedAt }}</span>
                    </div>
                </div>
            </div>
            <div class="title">
                <div class="title__block-1"></div>
                <div class="title__block-2"><span>FAKTURA</span></div>
                <div class="title__block-3"></div>
            </div>
            <div class="clientDescription">
                <div class="column-left">
                    <div class="row-1">
                        <div>
                            <span class="clientDescription--title"
                                >SPRZEDAWCA:</span
                            >
                        </div>
                        <div class="column-left__item-wrapper">
                            <span>{{ getSettingsDetails.companyName }}</span>
                            <span
                                >{{ `${$t('service:str')} `
                                }}{{ getSettingsDetails.companyAddress }}
                            </span>
                            <span>NIP: {{ getSettingsDetails.nip }}</span>
                            <span>BDO: {{ getSettingsDetails.bdo }}</span>
                            <span>{{ getSettingsDetails.website }}</span>

                            <span>{{ getSettingsDetails.companyEmail }}</span>
                        </div>
                    </div>
                </div>

                <div class="column-right">
                    <div class="row-1">
                        <div>
                            <span class="clientDescription--title"
                                >ODBIORCA</span
                            >
                        </div>
                        <div class="column-right__item-wrapper">
                            <span>{{ getContractor.name }}</span>
                            <span
                                >{{ `${$t('service:str')} ` }}
                                {{ getContractor.street }}
                                {{ getContractor.houseNumber }},
                                {{ getContractor.postCode }}
                                {{ getContractor.city }}</span
                            >
                            <span>NIP: {{ getContractor.NIP }}</span>
                        </div>
                    </div>
                    <div class="row-2">
                        <div>
                            <span class="clientDescription--title"
                                >{{
                                    $t(
                                        'rent-contracts:rent-info.buyer'
                                    ).toUpperCase()
                                }}:</span
                            >
                        </div>
                        <div class="column-right__item-wrapper">
                            <span>{{ getContractorBuyer.name }}</span>
                            <span
                                >{{ `${$t('service:str')} ` }}
                                {{ getContractorBuyer.street }}
                                {{ getContractorBuyer.houseNumber }},
                                {{ getContractorBuyer.postCode }}
                                {{ getContractorBuyer.city }}
                            </span>
                            <span>NIP: {{ getContractorBuyer.NIP }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <span class="middle-text"
                >Dotyczy umowy nr:
                {{
                    this.data.settlementTableData.content.rentInfoData[0]
                        .contractNo
                }}</span
            >

            <div class="central-table-wrapper">
                <table>
                    <tr>
                        <th class="column-to-center">Lp.</th>
                        <th class="column-to-left">Nazwa towaru / usługi</th>
                        <th>Ilość</th>
                        <th>J.m.</th>
                        <th>VAT</th>
                        <th>Cena netto</th>
                        <th>Wartość netto</th>
                    </tr>
                    <tr
                        class="main-counter"
                        v-if="
                            checkIsCostEqualZero(
                                invoiceRentDevices(
                                    this.data.settlementTableData.content
                                        .rentAnnex.rentDevices
                                )
                            )
                        "
                    >
                        <td class="column-to-center"></td>
                        <td class="column-to-left">
                            Najem urządzeń
                        </td>
                        <td>
                            1
                        </td>
                        <td>szt.</td>
                        <td>23%</td>
                        <td>
                            {{
                                invoiceRentDevices(
                                    this.data.settlementTableData.content
                                        .rentAnnex.rentDevices
                                )
                            }}
                            zł
                        </td>
                        <td>
                            {{
                                invoiceRentDevices(
                                    this.data.settlementTableData.content
                                        .rentAnnex.rentDevices
                                )
                            }}
                            zł
                        </td>
                    </tr>
                    <tr
                        class="main-counter"
                        v-if="
                            checkIsCostEqualZero(
                                summaryMono(
                                    this.data.settlementTableData.content,
                                    'monthlyMono',
                                    'monoPrice'
                                ).sum
                            )
                        "
                    >
                        <td class="column-to-center"></td>
                        <td class="column-to-left">Wydruki czarne</td>
                        <td>1</td>
                        <td>szt.</td>
                        <td>23%</td>
                        <td>
                            {{
                                summaryMono(
                                    this.data.settlementTableData.content,
                                    'monthlyMono',
                                    'monoPrice'
                                ).sum
                            }}
                            zł
                        </td>
                        <td>
                            {{
                                summaryMono(
                                    this.data.settlementTableData.content,
                                    'monthlyMono',
                                    'monoPrice'
                                ).sum
                            }}
                            zł
                        </td>
                    </tr>
                    <tr
                        class="main-counter"
                        v-if="
                            checkIsCostEqualZero(
                                summaryColor(
                                    this.data.settlementTableData.content,
                                    'monthlyColor',
                                    'colorPrice'
                                ).sum
                            )
                        "
                    >
                        <td class="column-to-center"></td>
                        <td class="column-to-left">Wydruki kolorowe</td>
                        <td>1</td>
                        <td>szt.</td>
                        <td>23%</td>
                        <td>
                            {{
                                summaryColor(
                                    this.data.settlementTableData.content,
                                    'monthlyColor',
                                    'colorPrice'
                                ).sum
                            }}
                            zł
                        </td>
                        <td>
                            {{
                                summaryColor(
                                    this.data.settlementTableData.content,
                                    'monthlyColor',
                                    'colorPrice'
                                ).sum
                            }}
                            zł
                        </td>
                    </tr>
                    <tr
                        class="main-counter"
                        v-if="
                            checkIsCostEqualZero(
                                summaryScan(
                                    this.data.settlementTableData.content,
                                    'monthlyMono',
                                    'monoPrice'
                                ).sum
                            )
                        "
                    >
                        <td class="column-to-center"></td>
                        <td class="column-to-left">Skany</td>
                        <td>1</td>
                        <td>szt.</td>
                        <td>23%</td>
                        <td>
                            {{
                                summaryScan(
                                    this.data.settlementTableData.content,
                                    'monthlyMono',
                                    'monoPrice'
                                ).sum
                            }}
                            zł
                        </td>
                        <td>
                            {{
                                summaryScan(
                                    this.data.settlementTableData.content,
                                    'monthlyMono',
                                    'monoPrice'
                                ).sum
                            }}
                            zł
                        </td>
                    </tr>
                    <tr
                        class="main-counter"
                        v-if="
                            checkIsCostEqualZero(
                                setMonitoringCost(
                                    this.data.settlementTableData.content
                                        .rentDevices
                                )
                            )
                        "
                    >
                        <td class="column-to-center"></td>
                        <td class="column-to-left">Monitoring</td>
                        <td>1</td>
                        <td>szt.</td>
                        <td>23%</td>
                        <td>
                            {{
                                setMonitoringCost(
                                    this.data.settlementTableData.content
                                        .rentDevices
                                )
                            }}
                            zł
                        </td>
                        <td>
                            {{
                                setMonitoringCost(
                                    this.data.settlementTableData.content
                                        .rentDevices
                                )
                            }}
                            zł
                        </td>
                    </tr>
                </table>
            </div>
            <div class="before-summary-wrap">
                <div class="thanks-text">
                    <span>Dziękujemy za korzystanie z naszych usług.</span>
                </div>
                <div class="total-netto">
                    <span
                        ><span>Razem netto:</span>
                        <span>{{
                            this.data.settlementTableData.content.monthlyCosts.toFixed(
                                2
                            )
                        }}</span>
                        PLN</span
                    >
                </div>
            </div>
            <div class="summary-wrapper">
                <div class="col-left">
                    <div class="payment-condition">
                        <span style="font-weight: 600;">Warunki płatności</span>
                        <div class="payment-condition__inner-wrapper">
                            <span>
                                Forma płatności
                            </span>
                            <span>przelew bankowy</span>
                            <span>
                                Termin zapłaty
                            </span>
                            <span>
                                {{
                                    getPaymentDeadlineDate(
                                        this.client.client.annex.createdAt,
                                        this.client.client.annex.paymentDeadline
                                    )
                                }}</span
                            >
                            <span>
                                Nazwa banku
                            </span>
                            <span>{{ getSettingsDetails.bankName }}</span>
                            <span>
                                Nr konta
                            </span>
                            <span>{{
                                getSettingsDetails.bankAccountNumber
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-right">
                    <div class="mini-summary">
                        <table>
                            <tr>
                                <th></th>
                                <th>Stawka VAT</th>
                                <th>VAT</th>
                                <th>Netto</th>
                                <th>Brutto</th>
                            </tr>
                            <tr>
                                <th>Razem:</th>
                                <td>-</td>
                                <td>
                                    {{
                                        (
                                            this.data.settlementTableData
                                                .content.monthlyCosts * 0.23
                                        ).toFixed(2)
                                    }}
                                </td>
                                <td>
                                    {{
                                        this.data.settlementTableData.content.monthlyCosts.toFixed(
                                            2
                                        )
                                    }}
                                </td>
                                <td>{{ this.totalPriceBrutto() }}</td>
                            </tr>
                            <tr>
                                <th>w tym:</th>
                                <td>23%</td>
                                <td>
                                    {{
                                        (
                                            this.data.settlementTableData
                                                .content.monthlyCosts * 0.23
                                        ).toFixed(2)
                                    }}
                                </td>
                                <td>
                                    {{
                                        this.data.settlementTableData.content.monthlyCosts.toFixed(
                                            2
                                        )
                                    }}
                                </td>
                                <td>{{ this.totalPriceBrutto() }}</td>
                            </tr>
                        </table>
                    </div>
                    <div class="total-brutto">
                        <div class="col-left">
                            <span>Razem brutto <br />do zapłaty:</span>
                        </div>
                        <div class="col-right">
                            <span>{{ this.totalPriceBrutto() }} PLN</span>
                        </div>
                    </div>
                    <div class="numtoword" v-if="priceFormater">
                        {{
                            priceFormater.convert(
                                parseFloat(this.totalPriceBrutto())
                            )
                        }}
                    </div>
                </div>
            </div>
            <div class="significant">
                <div class="col-left">
                    <span>Osoba wystawiająca fakturę</span
                    ><span
                        >{{
                            this.data.settlementTableData.content.createdBy.name
                        }}
                        {{
                            this.data.settlementTableData.content.createdBy
                                .lastname
                        }}</span
                    >
                </div>
                <div class="col-right"></div>
            </div>
            <div class="footer">
                <span>{{ getSettingsDetails.website }}</span>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import store from '../../../../store'
import jspdf from 'jspdf'
import domtoimage from 'dom-to-image'
import moment from 'moment'

import PriceFormater from 'price-to-words-pl'
import { getNewInvoiceNumber } from './utils/invoiceNumberModifier'

export default {
    data() {
        return {
            path: `${process.env.VUE_APP_SERVER}/secured/logo/`,
            forTechnic: true,
            vat: 23,
            partsBrutto: 0,
            isPrice: false,
            x: '',
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: 2,
                masked: false,
            },
            emailMenu: false,
            representativeMail: '',
            emailSend: false,
            priceFormater: '',
            getNewInvoiceNumber: getNewInvoiceNumber,
        }
    },

    computed: {
        ...mapGetters([
            'getServiceDetails',
            'getSettingsDetails',
            'getPrevIntervention',
            'getEstimates',
            'getProfileDetails',
        ]),
        invoiceCreatedAt() {
            return (
                moment(this.data.settlementCreatedAt).format(`DD-MM-YYYY`) || ''
            )
        },
        getContractor() {
            const contractor = this.client.client.annex.contractor

            return contractor
        },
        getContractorBuyer() {
            if (!this.client.client.annex?.contractorBuyer) {
                return this.client.client.annex.contractor
            } else {
                return this.client.client.annex.contractorBuyer
            }
        },
    },

    components: {},

    methods: {
        checkIsCostEqualZero(param) {
            if (typeof param !== String) {
                param.toString()
            }
            if (param == 0.0 || param == 0) {
                return false
            }

            return true
        },
        invoiceRentDevices(rentDevices) {
            let sum = 0.0
            sum = rentDevices.reduce(function (acc, val) {
                return acc + val.rent
            }, 0)

            return sum.toFixed(2)
        },

        setMonitoringCost(rentDevices) {
            let sum = rentDevices.reduce(function (acc, val) {
                return acc + (val.monitoringCost || 0)
            }, 0)
            if (isNaN(sum)) {
                return 0
            }
            return sum.toFixed(2)
        },

        summaryMono(items) {
            let sum = 0

            items.rentSettlements.forEach(element => {
                const monthlyMono = element.monthlyMono

                const devices = items.rentAnnex.rentDevices.filter(
                    e => e._id === element.rentDevices
                )
                const monoPrice = devices[0].monoPrice
                sum += monoPrice * monthlyMono
            })
            sum = sum.toFixed(2)
            return { sum }
        },
        summaryColor(items) {
            let sum = 0

            items.rentSettlements.forEach(element => {
                const monthlyColor = element.monthlyColor

                const devices = items.rentAnnex.rentDevices.filter(
                    e => e._id === element.rentDevices
                )
                const monoPrice = devices[0].colorPrice
                sum += monoPrice * monthlyColor
            })
            sum = sum.toFixed(2)
            return { sum }
        },
        summaryScan(items) {
            let sum = 0

            items.rentSettlements.forEach(element => {
                const monthlyScan = element.monthlyScan

                const devices = items.rentAnnex.rentDevices.filter(
                    e => e._id === element.rentDevices
                )
                const scanPrice = devices[0].scanPrice
                sum += scanPrice * monthlyScan
            })
            sum = sum.toFixed(2)
            return { sum }
        },
        totalPriceBrutto() {
            const total =
                this.data.settlementTableData.content.monthlyCosts * 0.23 +
                this.data.settlementTableData.content.monthlyCosts
            return total.toFixed(2)
        },
        getPaymentDeadlineDate(createdAt, paymentDeadlineNumber) {
            if (!createdAt || !paymentDeadlineNumber) {
                return ''
            }
            const settlmentDate = moment(createdAt)
                .add(paymentDeadlineNumber, 'days')
                .format('DD-MM-YYYY')
            if (!settlmentDate) {
                return ''
            }
            return settlmentDate
        },
    },
    props: {
        type: {
            type: String,
        },
        data: {
            type: Object,
        },
        client: {
            type: Object,
        },
    },

    mounted() {
        this.priceFormater = new PriceFormater()
    },

    beforeMount() {
        store.dispatch('fetchSettings', {
            type: 'add',
        })
    },
}
</script>

<style lang="scss" scoped>
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    color: #4a4a4a;
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.page {
    width: 210mm;
    height: 297mm;
    margin: 10mm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    position: relative;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.subpage {
    height: 100%;

    .header {
        padding-top: 40px;
        padding-left: 40px;
        padding-right: 30px;
        display: grid;
        grid-template-columns: 2fr 1fr;

        .logo {
            padding-left: 10px;

            img {
                max-width: 203px;
                max-height: 45px;
                display: block;
            }
        }

        &__invoice-wrapper-info {
            display: flex;
            justify-content: space-between;
            font-size: 0.625em;
        }
    }

    .title {
        margin-top: 30px;
        display: flex;
        font-size: 2rem;

        &__block-1 {
            width: 500px;
            height: 50px;
            background-color: #4c517d;
            display: block;
        }

        &__block-2 {
            display: flex;
            height: 50px;

            padding-left: 20px;
            padding-right: 20px;
            line-height: 50px;
        }

        &__block-3 {
            height: 50px;
            float: left;
            flex-grow: 1;
            background-color: #4c517d;
            display: block;
        }
    }

    .clientDescription {
        margin-top: 50px;
        display: grid;
        grid-template-columns: 500px 1fr;
        font-size: 0.6875em;

        &--title {
            font-weight: bold;
        }

        .column-left {
            display: grid;
            padding-left: 50px;

            &__item-wrapper {
                padding: 0px;
                margin: 0px;
                display: grid;
                grid-template: column;
            }
        }

        .column-right {
            display: grid;
            padding-right: 20px;

            &__item-wrapper {
                margin: 0px;

                display: grid;
                grid-template: column;
            }

            .row-2 {
                margin-top: 30px;
            }
        }
    }

    .middle-text {
        padding-left: 50px;
        font-size: 0.625em;
    }

    .central-table-wrapper {
        margin-left: 50px;
        margin-top: 5px;

        .column-to-left {
            text-align: start;
        }

        .column-to-center {
            text-align: center;
        }

        table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 95%;
            border: 1px solid rgb(128, 128, 128);
            font-size: 0.625em;
            counter-reset: rowNumber;
        }

        table .main-counter {
            counter-increment: rowNumber;
        }

        table tr td:first-child::before {
            content: counter(rowNumber);
        }

        th {
            background-color: #4c517d;
            color: #f5f5f5;
        }

        th,
        td {
            text-align: end;
            height: 38px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 15px;
        }

        tr:nth-child(2n) {
            background-color: #f5f5f5;
        }
    }

    .before-summary-wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-left: 50px;
        padding-right: 38px;
        margin-top: 10px;

        .thanks-text {
            font-size: 0.75em;
        }

        .total-netto {
            font-weight: 700;
            line-height: 14px;
            font-size: 0.75em;
            color: #4a4a4a;
            justify-self: end;
        }

        .total-netto > span > span:nth-child(2n) {
            margin-left: 20px;
        }
    }

    .summary-wrapper {
        margin-top: 60px;
        padding-top: 10px;
        padding-left: 50px;
        padding-right: 38px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .col-left {
            font-size: 0.75em;

            .payment-condition {
                margin-top: 20px;
                display: flex;
                flex-direction: column;

                &__inner-wrapper {
                    margin-top: 2px;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                }
            }
        }

        .col-right {
            display: grid;
            text-align: right;

            .total-brutto {
                margin-top: 5px;
                width: 80%;
                height: 52px;
                justify-self: end;
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: center;
                padding: 5px 15px 5px 15px;
                background-color: #4c517d;
                text-align: start;
                color: white;
            }

            .numtoword {
                margin-top: 5px;
                font-size: 0.5em;
                text-align: left;
                justify-self: end;
            }

            .mini-summary {
                text-align: right;
                margin-top: 15px;

                table {
                    width: 65%;
                    float: right;
                }

                th,
                td {
                    font-size: 0.5em;
                    padding: 0px 0px 0px 0px;
                }
            }
        }
    }

    .significant {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding-top: 90px;
        padding-left: 60px;
        margin-bottom: 10px;
        font-size: 0.625em;

        .col-left {
            display: flex;
            flex-direction: column;

            align-items: flex-start;
        }

        .col-right {
            display: flex;
            flex-direction: column;
            padding-top: 20px;
            padding-left: 30px;
            align-items: flex-start;

            hr.line {
                border: 1px solid rgb(1, 1, 1, 0.5);
                margin-bottom: 10px;
                width: 70%;
            }
        }
    }

    .footer {
        margin-left: -1px;
        width: 210mm;
        height: 25px;
        margin-bottom: 20px;
        color: white;
        text-align: center;
        position: absolute;
        bottom: 0;
        background-color: #4c517d;
    }
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    html,
    body {
        width: 210mm;
        height: 297mm;
        padding: 0px !important;
    }

    .page {
        margin: 2px;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
        -webkit-print-color-adjust: exact;
    }

    .subpage {
        height: 256mm;
    }
}
</style>
